import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		// isLogin: false,//备用：不可靠，刷新页将丢失，需要将登录状态保存于本地存储
		isLogin: localStorage.getItem('TOKEN')!=null,
		current_outlet_id: 0
	},
	mutations: {
		setCurrentOutletId(state, outletid) {
			state.current_outlet_id = outletid;
		},
		setLoginStatus(state, isLogin) {
			state.isLogin = isLogin;
		},
	},
	actions: {},
	modules: {}
})
