import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'

import Toasted from 'vue-toasted';

import VueConfirmDialog from 'vue-confirm-dialog'

Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import global from './components/Global.vue'
Vue.prototype.GLOBAL = global

Vue.use(VueAxios, axios)

Vue.use(Toasted)

import * as echarts from 'echarts';//引入echarts
Vue.prototype.$echarts = echarts //引入组件

// 引入vue-amap
import VueAMap from 'vue-amap';
Vue.use(VueAMap);

import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

Vue.use(VueQuillEditor, /* { default global options } */)

// 初始化vue-amap
VueAMap.initAMapApiLoader({
  // 高德的key
  key: '48176d3b9a6d419a81ab66588b530df7',
  // 插件集合
  plugin: [
	  'AMap.Geolocation', 'AMap.Geocoder',
	  'AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 
	  'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  // 高德 sdk 版本，默认为 1.4.4
  v: '1.4.4'
});


// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
// Init plugin
Vue.use(Loading, {color: '#03a9f4'});

import Tooltip from 'vue-directive-tooltip';
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css';
Vue.use(Tooltip);

//月份选择器
import { MonthPicker } from 'vue-month-picker'
import { MonthPickerInput } from 'vue-month-picker'
Vue.use(MonthPicker)
Vue.use(MonthPickerInput)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

if (module.hot) {
  module.hot.accept();
}

Vue.prototype.logoff = ()=>{
	console.log('--- 登录超时 ---')
	
	Vue.toasted.error( '登录超时', {
		position: 'top-center',
		duration: '2000',
	})
	
	localStorage.clear();
	router.push({name:"login"})
}