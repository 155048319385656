<template>
    <div id="app">
	  <vue-confirm-dialog></vue-confirm-dialog>	
      <router-view />
    </div>
</template>

<script>
    export default {
        name: 'App',

        data: function() {
            return {
            }
        },
        components: {
        },
        created: function() {
            console.warn('--- created @App.vue --- ')
        },

        mounted: function() {
            console.warn('--- mounted @App.vue --- ')
        },

        methods: {
        },
    }
</script>

<style>
    @import "./assets/css/app.css";
</style>
