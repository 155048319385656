<template>
    <div class="admin-layout">
        <!-- Pre loader -->
        <div id="loader" class="">
        </div>

        <!-- aside start -->
        <sibebar :user_role='user_role'></sibebar>
        <!-- aside end -->

        <div class="has-sidebar-left has-sidebar-tabs">
            <!-- 非组件navbar -->
            <div class="sticky">
                <div class="navbar navbar-expand d-flex justify-content-between bd-navbar white shadow">
                    <div class="relative">
                        <div class="d-flex">
                            <div class="d-none d-md-block">
                                <div class="nav-title form-inline">
									<!-- 福利云平台不需要下拉企业列表 -->
                                    <!-- <i class="icon icon-map-marker"></i>
                                    <select v-if="isShow" v-model="outlet_id" @change="onChangeOutlet"
                                        class="form-control mr-2" style="border: 0px;">
                                        <option :value="item.id" v-for="(item,index) in outletlist" v-bind:key="index">
                                            {{item.name}}{{item.is_mainshop==1?'(总店)':''}}
                                        </option>
                                    </select> -->
                                    <span class="font-weight-bold">{{title}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Top Menu Start -->
                    <div class="navbar-custom-menu">
                        <ul class="nav navbar-nav">

                            <!-- search -->
                            <!-- <li>
                            <a class="nav-link " data-toggle="collapse" data-target="#navbarToggleExternalContent"
                                aria-controls="navbarToggleExternalContent" aria-expanded="false"
                                aria-label="Toggle navigation">
                                <i class="icon-search3"></i>
                            </a>
                        </li> -->

                            <!-- Messages-->
                            <li class="dropdown custom-dropdown messages-menu" v-if="false">
                                <a href="#" class="nav-link" data-toggle="dropdown">
                                    <i class="icon-envelope-o"></i>
                                    <span class="badge badge-success badge-mini rounded-circle">1</span>
                                </a>
                                <ul class="dropdown-menu dropdown-menu-right">
                                    <li>
                                        <!-- inner menu: contains the actual data -->
                                        <ul class="menu pl-2 pr-2">
                                            <!-- start message -->
                                            <li>
                                                <a href="#">
                                                    <div class="avatar float-left">
                                                        <img src="assets/img/dummy/u4.png" alt="">
                                                        <span class="avatar-badge busy"></span>
                                                    </div>
                                                    <h4>
                                                        续费
                                                        <small><i class="icon icon-clock-o"></i> 5 分钟</small>
                                                    </h4>
                                                    <p>您的授权将于14日后到期?</p>
                                                </a>
                                            </li>
                                            <!-- end message -->

                                        </ul>
                                    </li>
                                    <li class="footer s-12 p-2 text-center"><a href="#">查看所有消息</a></li>
                                </ul>
                            </li>

                            <!-- User Account-->
                            <!-- <li class="dropdown custom-dropdown user user-menu ">
                            <a href="#" class="nav-link" data-toggle="control-sidebar">
                                <img src="assets/img/dummy/u1.png" class="user-image" alt="User Image">
                            </a>
                        </li> -->

                            <li class="dropdown custom-dropdown user user-menu">
                                <a href="#" class="nav-link" data-toggle="dropdown" aria-expanded="false">
                                    <img src="../assets/img/dummy/u1.png" class="user-image" alt="User Image">
                                    <i class="icon-more_vert "></i>
                                </a>
                                <div class="dropdown-menu p-4 dropdown-menu-right">
                                    <div class="row box justify-content-between">
                                        <div class="col">
                                            <i class="icon icon-home2"></i> {{user_belong}}
                                        </div>

                                    </div>
                                    <div class="row box justify-content-between mt-2">
                                        <div class="col s-14"><i class="icon icon-user ml-2"></i>
										{{user_name}}
										<!-- ({{user_role=='USER'?'企业用户':'平台用户'}}) -->
										<span v-if="user_role=='AGENT'">(服务商)</span>
										<span v-if="user_role=='ADMIN'">(平台用户)</span>
										<span v-if="user_role=='USER'">(企业用户)</span>
                                        </div>

                                    </div>
                                    <hr>
                                    <div class="row box justify-content-between my-4">
                                        <div class="col">
                                            <a @click.stop="goAccount">
                                                <i class="icon-user blue lighten-2 avatar  r-5"></i>
                                                <div class="pt-1">帐号与安全</div>
                                            </a>
                                        </div>
                                        <div class="col" @click.stop="logout"><a href="#">
                                                <i class="icon-power-off pink lighten-1 avatar  r-5"></i>
                                                <div class="pt-1">退出</div>
                                            </a></div>

                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- #非组件navbar -->

            <!-- 路由匹配到的组件将渲染在这里 -->
            <router-view ref="currentView"></router-view>
            <!-- #路由匹配到的组件将渲染在这里 -->

        </div>

        <!--Add New Message Fab Button-->
        <!-- 
    <a @click="add" href="#" class="btn-fab btn-fab-md fab-right fab-right-bottom-fixed shadow btn btn-secondary"><i class="icon-add"></i></a>
     -->

        <div class="control-sidebar-bg shadow white fixed"></div>

    </div>
</template>

<script>
    import Aside from './aside.vue'

    export default {
        // name: 'App',

        data: function() {
            return {
                // isLogin: false,
                isLogin: this.$store.state.isLogin,

                title: '首页',
                isShow: true,

                token: '',
                outlet_id: 0,
                outlet_name: '',
                user_name: '',
				user_role: '',

                setting: {},
                outletlist: [],
				
				user_belong: '',//登录用户归属
            }
        },
        components: {
            sibebar: Aside
        },
        created: function() {
            console.warn('--- created @Admin.vue --- ')
            let self = this;

            //1.检查登录
            let token = localStorage.getItem('TOKEN');
            if (token != null) {
                self.token = token;
                self.isLogin = true;
            }
            console.log('--- isLogin @Admin.vue -> ', self.isLogin)

            //2.取得店铺信息
            if (self.isLogin) {
                let lsLoginData = JSON.parse(localStorage.getItem('LOGINDATA'));
                console.log(lsLoginData.dept_name)
                self.ent_id = lsLoginData.ent_id;
				self.dept_id = lsLoginData.dept_id;
				
                self.ent_name = lsLoginData.ent_name;
				self.dept_name = lsLoginData.dept_name;
				self.user_belong  = self.ent_name || self.dept_name;
				
                self.user_name = lsLoginData.user_account;
				self.user_role = lsLoginData.user_role;

                // self.setting = JSON.parse(localStorage.getItem('SETTING'));

                // self.outletlist = JSON.parse(localStorage.getItem('OUTLETLIST'));
                // console.log('--- outlet list ->', self.outletlist)

                this.$store.commit('setCurrentOutletId', self.dept_id); //初始化当前 店铺 ID >>当前企业（B）

                self.fetchData(); //路由 watch
            }
        },

        watch: {
            // 如果路由有变化，会再次执行该方法
            '$route': 'fetchData'
        },

        mounted: function() {
            console.warn('--- mounted @Admin.vue --- ')
            // let self = this;
        },

        methods: {
            fetchData() {
                let self = this;
                console.log('--- this.$router @ fetchData ->', this.$route);

                // 通过 watch 控制navbar 的 title 与店铺列表显示
                self.title = this.$route.meta.title || '首页';
                self.isShow = this.$route.meta.isShow || false;
                self.readonly = this.$route.meta.readonly || false;
            },

			//未使用
            onChangeOutlet() {
                let self = this;

                if (self.readonly) {
                    return;
                }

                if (self.outletlist.length == 1) {
                    return;
                }

                console.log('--- outlte_id @ onChangeOutlet ->', self.outlet_id);
                // this.$emit('outletchange', self.outlet_id);

                console.log('--- this.$refs ->', this.$refs);
                this.$refs.currentView.onOutletChangeListener(self.outlet_id); //调用路由组件中的方法，重载子组件数据

                console.log('--- this.$store ->', this.$store);
                // 本文件中 store 即this.$store
                this.$store.commit('setCurrentOutletId', self.outlet_id) //存储当前选中的店铺 ID，作为全局变量，跨页使用
            },

            goAccount() {

                console.warn('--- this.$router @ goAccount ->', this.$router);
                //已在本页，不导航
                if (this.$router.currentRoute.fullPath != '/account') {
                    this.$router.push({
                        name: 'account'
                    }).catch(err => {err});
                }
            },

            logout() {
                //TODO:调用 logout 接口，清除服务器端登录状态
            	let self = this;
				
				let token = localStorage.getItem('TOKEN');
				if (!token) {
				    return;
				}
            	
            	this.axios.get(this.GLOBAL.baseURI + 'logoff', {
            	    params: {token: token}
            	})
            	.then(function(response) {
            	    console.log(response);
            	    return response.data;
            	})
            	.catch(function(error) {
            	    console.log(error);
            	})
            	.then(function(data) {
            	    // always executed
            	    console.log(data.data);
            	    
            	    
            	    if (data.code == 200) { 
            			self.$toasted.success( '退出登录', {
            				position: 'top-center',
            				duration: '2000',
            			})
            	        
            			setTimeout(()=>{
            				localStorage.clear();
            				self.$router.push({name:'login'})
            			},1000)
            	       
            	    }
            	    else{
            	        // alert(data.message)
            			self.$toasted.error( data.message, {
            				position: 'top-center',
            				duration: '2000',
            			})
            	    }
            	});
            	//END
            },
        },
    }
</script>

<style>
</style>
