import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'

import global from '../components/Global.vue'

import AppLayout from '../components/Admin.vue'
import EmptyComponent from '../components/Auth.vue'

import dashboard from '../views/dashboard.vue'

Vue.use(VueRouter)
// Vue.use(axios)

const routes = [
	{
        path: '/',
        redirect: { name: 'login' },
    },
	
	{
	  path: '*',
	  redirect: { name: '404' },
	},
	
	{
	  name: 'card',
	  path: '/card',
	  component: () => import('../views/card.vue'),
	},
	
	{
	  name: '404',
	  path: '/404',
	  component: () => import('../views/page404.vue'),
	},
	
	{
	  name: '403',
	  path: '/403',
	  component: () => import('../views/page403.vue'),
	},

    {
        path: '/auth',
        component: EmptyComponent,
        children: [{
			    path: '',
			    redirect: {
			        name: 'login'
			    },
			},
			{
                name: 'login',
                path: 'login',
                component: () => import('../views/login.vue'),
            },
			
			{
			    name: 'superadmin',
			    path: 'OxTNiiS9PjlWIDD1KEgU71ZjZQHNxh',
			    component: () => import('../views/superadmin.vue'),
			    meta: {
			        title: '帐号与安全',
			        isShow: false
			    }
			}, 
        ],
    },

    {
        name: 'admin',
        path: '/admin',
        component: AppLayout,
        children: [{
				name: 'dashboard',
				path: 'dashboard',
				component: () => import('../views/dashboard.vue'),
				meta: {
					title: '首页',
					isShow: false,
				}
            },
			{
			    name: 'enterprises',
			    path: 'enterprises',
			    component: () => import('../views/enterprises.vue'),
			    meta: {title: '企业码列表',isShow: true}
			},
			{	name: 'entquotas',
				path: 'entquotas', 
				component:  () => import('../views/entquotas.vue'),
				meta: {title: '服务商额度管理',isShow: true},
			},
			{	name: 'entquotalogs',
				path: 'entquotalogs', 
				component:  () => import('../views/entquotalogs.vue'),
				meta: {title: '服务商额度充值记录',isShow: true},
			},
			{
			    name: 'departments',
			    path: 'departments',
			    component: () => import('../views/departments.vue'),
			    meta: {title: '企业列表',isShow: true}
			},
			{	name: 'quotas',
				path: 'quotas', 
				component:  () => import('../views/quotas.vue'),
				meta: {title: '企业额度管理',isShow: true},
			},
			{	name: 'quotalogs',
				path: 'quotalogs', 
				component:  () => import('../views/quotalogs.vue'),
				meta: {title: '企业额度充值记录',isShow: true},
			},
			{
			    name: 'merchants',
			    path: 'merchants',
			    component: () => import('../views/merchants.vue'),
			    meta: {title: '商户白名单',isShow: true}
			},
			{
			    name: 'addmerchant',
			    path: 'addmerchant',
			    component: () => import('../views/addmerchant.vue'),
			    meta: {title: '添加白名单',isShow: true}
			},
			{
			    name: 'products',
			    path: 'products',
			    component: () => import('../views/products.vue'),
			    meta: {title: '商品列表',isShow: true}
			},
			// {	name: 'editliteproduct', 
			// 	path: 'editliteproduct', 
			// 	component:  () => import('../views/editliteproduct.vue'),
			// 	meta: {title: '简易录菜',isShow: true},
			// },
			{	name: 'editliteproduct',
				path: 'editliteproduct', 
				component:  () => import('../views/editliteproduct.vue'),
				meta: {title: '创建商品',isShow: true},
			},
			// {	name: 'addproduct',
			// 	path: 'addproduct', 
			// 	component:  () => import('../views/editproduct.vue'),
			// 	meta: {title: '创建商品',isShow: true},
			// },
			{	name: 'editproduct',
				path: 'editproduct', 
				component:  () => import('../views/editproduct.vue'),
				meta: {title: '编辑商品',isShow: true},
			},
			// {	name: 'importproducts',
			// 	path: 'importproducts', 
			// 	component:  () => import('../views/importproducts.vue'),
			// 	meta: {title: '导入商品',isShow: true},
			// },
			
			{
			    name: 'subdepartments',
			    path: 'subdepartments',
			    component: () => import('../views/subdepartments.vue'),
			    meta: {title: '部门列表',isShow: false}
			},
			{
			    name: 'editcustomergroup',
			    path: 'editcustomergroup',
			    component: () => import('../views/editcustomergroup.vue'),
			    meta: {title: '级别额度',isShow: false}
			},
			{
			    name: 'editcustomer',
			    path: 'editcustomer',
			    component: () => import('../views/editcustomer.vue'),
			    meta: {title: '创建会员',isShow: true}
			},
			{	name: 'importcustomers',
				path: 'importcustomers', 
				component:  () => import('../views/importcustomers.vue'),
				meta: {title: '导入会员',isShow: true},
			},
			{	name: 'staffs',
				path: 'staffs', 
				component:  () => import('../views/staffs.vue'),
				meta: {title: '员工列表',isShow: true},
			},
			{	name: 'importstaffs',
				path: 'importstaffs', 
				component:  () => import('../views/importstaffs.vue'),
				meta: {title: '批量添加员工',isShow: true},
			},
			{	name: 'importfreezestaffs',
				path: 'importfreezestaffs', 
				component:  () => import('../views/importfreezestaffs.vue'),
				meta: {title: '批量冻结员工',isShow: true},
			},
			{	name: 'importdeletestaffs',
				path: 'importdeletestaffs', 
				component:  () => import('../views/importdeletestaffs.vue'),
				meta: {title: '批量注销员工',isShow: true},
			},
			{	name: 'customer',
				path: 'customer', 
				component:  () => import('../views/customer.vue'),
				meta: {title: '会员资料',isShow: true},
			},
			
			{	name: 'institutions',
				path: 'institutions', 
				component:  () => import('../views/institutions.vue'),
				meta: {title: '制度列表',isShow: true},
			},
			{	name: 'addinstitution',
				path: 'addinstitution', 
				component:  () => import('../views/editinstitution.vue'),
				meta: {title: '创建制度',isShow: true},
			},
			
			{	name: 'institution',
				path: 'institution', 
				component:  () => import('../views/institution.vue'),
				meta: {title: '发放制度',isShow: true},
			},
			{	name: 'importquotas',
				path: 'importquotas', 
				component:  () => import('../views/importquotas.vue'),
				meta: {title: '批量发放',isShow: true},
			},
			{	name: 'userinstitutions',
				path: 'userinstitutions', 
				component:  () => import('../views/userinstitutions.vue'),
				meta: {title: '发放记录',isShow: true},
			},
			
			{	name: 'giftcardstocks',
				path: 'giftcardstocks', 
				component:  () => import('../views/giftcardstocks.vue'),
				meta: {title: '福利卡批次管理',isShow: true},
			},
			{	name: 'addgiftcardstock',
				path: 'addgiftcardstock', 
				component:  () => import('../views/editgiftcardstock.vue'),
				meta: {title: '创建福利卡批次库存',isShow: false},
			},
			{	name: 'editgiftcardstock',
				path: 'editgiftcardstock', 
				component:  () => import('../views/editgiftcardstock.vue'),
				meta: {title: '修改福利卡批次库存',isShow: false},
			},
			
			{	name: 'giftcards',
				path: 'giftcards', 
				component:  () => import('../views/giftcards.vue'),
				meta: {title: '福利卡列表',isShow: true},
			},
			{	name: 'giftcardlogs',
				path: 'giftcardlogs', 
				component:  () => import('../views/giftcardlogs.vue'),
				meta: {title: '福利卡发放记录',isShow: true},
			},
			{	name: 'importgiftcards',
				path: 'importgiftcards', 
				component:  () => import('../views/importgiftcards.vue'),
				meta: {title: '批量发放福利卡',isShow: true},
			},
			
			{	name: 'bills',
				path: 'bills', 
				component:  () => import('../views/bills.vue'),
				meta: {title: '账单明细',isShow: true},
			},
			
			{	name: 'saleorders',
				path: 'saleorders', 
				component:  () => import('../views/saleorders.vue'),
				meta: {title: '消费订单',isShow: true},
			},
			{	name: 'rechargeorders',
				path: 'rechargeorders', 
				component:  () => import('../views/rechargeorders.vue'),
				meta: {title: '充值订单',isShow: true},
			},
			
			{	name: 'userinstitutionreport',
				path: 'userinstitutionreport', 
				component:  () => import('../views/institutionreport.vue'),
				meta: {title: '发放报表',isShow: true},
			},
			
			{	name: 'billreport',
				path: 'billreport', 
				component:  () => import('../views/institutionreport.vue'),
				meta: {title: '账单报表',isShow: true},
			},
			
			{	name: 'quotareport',
				path: 'quotareport', 
				component:  () => import('../views/quotareport.vue'),
				meta: {title: '额度报表',isShow: true},
			},
			
			{	name: 'giftcardreport',
				path: 'giftcardreport', 
				component:  () => import('../views/giftcardreport.vue'),
				meta: {title: '福利卡报表',isShow: true},
			},
			
			{	name: 'balancereport',
				path: 'balancereport', 
				component:  () => import('../views/balancereport.vue'),
				meta: {title: '企业余额报表',isShow: true},
			},
			
			{	name: 'entbalancereport',
				path: 'entbalancereport', 
				component:  () => import('../views/entbalancereport.vue'),
				meta: {title: '服务商余额报表',isShow: true},
			},
			
			{	name: 'weekbillreport',
				path: 'weekbillreport', 
				component:  () => import('../views/weekbillreport.vue'),
				meta: {title: '周消费报表',isShow: true},
			},
			
			{	name: 'entweekbillreport',
				path: 'entweekbillreport', 
				component:  () => import('../views/entweekbillreport.vue'),
				meta: {title: '周消费报表',isShow: true},
			},
			
			{	name: 'myquotas',
				path: 'myquotas', 
				component:  () => import('../views/myquotas.vue'),
				meta: {title: '额度查询',isShow: true},
			},
			
			{	name: 'setting',
				path: 'setting', 
				component:  () => import('../views/setting.vue'),
				meta: {title: '系统设置',isShow: true},
			},
			{	name: 'messages',
				path: 'messages', 
				component:  () => import('../views/messages.vue'),
				meta: {title: '消息管理',isShow: true},
			},
			{
			    name: 'userlogs',
			    path: 'userlogs',
			    component: () => import('../views/userlogs.vue'),
			    meta: {
			        title: '企业登录日志',
			        isShow: true
			    }
			}, 
			{
			    name: 'employees',
			    path: 'employees',
			    component: () => import('../views/employees.vue'),
			    meta: {
			        title: '收银员',
			        isShow:  true
			    }
			}, 
			{
			    name: 'ausers',
			    path: 'ausers',
			    component: () => import('../views/ausers.vue'),
			    meta: {
			        title: 'A端用户管理',
			        isShow: true
			    }
			}, 
			{
			    name: 'entuserlogs',
			    path: 'entuserlogs',
			    component: () => import('../views/entuserlogs.vue'),
			    meta: {
			        title: '服务商登录日志',
			        isShow: true
			    }
			}, 
			{
			    name: 'users',
			    path: 'users',
			    component: () => import('../views/users.vue'),
			    meta: {
			        title: '用户管理',
			        isShow: true
			    }
			}, 
			{
			    name: 'account',
			    path: 'account',
			    component: () => import('../views/account.vue'),
			    meta: {
			        title: '帐号与安全',
			        isShow: false
			    }
			}, 
			
			
		],
    },

]

const router = new VueRouter({
    // mode: 'history',
    routes
})

//登录状态判断与访问权限控制
router.beforeEach((to, from, next) => {
    console.log('--- to->', to);
    // console.log('--- from->', from);
	// next();
	
	if (to.name == 'superadmin' || to.name == 'card') {
		console.log('--- to sa ---');
		next();
	}
    else{

		//登录判断：这儿跳转后，不会执行 vue 生命周期勾子
		let token = localStorage.getItem('TOKEN');
		console.log('--- TOKEN  ->', token);

		if (token !== null) {
			//TODO:访问服务器缓存数据，判断当前token是否失效
			console.log('--- axios @ router->', axios);

			//调用接口
			axios.get(global.baseURI + 'checkUserLogin', {
					params: {
						token: token
					}
				})
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function(data) {
					// always executed
					console.log(data.data);

					if (data.code == 200) {
						next();
					} else {
						//登录失效，去跳转登录页
						localStorage.clear();

						if (to.name == 'login') {
							next();
						} else {
							next('/auth/login');
						}
					}
				});
			//END

		} else {
			//没有登录，去跳转登录页
			localStorage.clear();

			if (to.name == 'login') {
				next();
			} else {
				next('/auth/login');
			}

		}
	}

})

export default router
