<script>
const appenv = "production";// dev|production

const project = 'LLM';//OP | LLM
const apiUrl = project=='LLM'?'https://api.2rices.cn/admin/':'https://api2.pandafuli.net/admin/';
const imgUrl = project=='LLM'?'https://llm-fly.oss-cn-shanghai.aliyuncs.com/':'https://panda-fuli.oss-cn-beijing.aliyuncs.com/';

const baseURI = appenv=='dev'?'https://api4-test.poslite.cn/admin/':apiUrl;
const baseImgURI = appenv=='dev'?'https://oss-pk-test.oss-cn-shenzhen.aliyuncs.com/':imgUrl;

const categorylist_dev = [
	//LLM
	// {name: '便民生活', value: 'DEFAULT'},
	// {name: '餐食外卖', value: 'MEAL'},
	// {name: '交通出行', value: 'CAR'},
	// {name: '通用津贴', value: 'COMMON'},
	//OP
	{name: '通用福利', value: 'DEFAULT'},
	{name: '餐饮福利', value: 'MEAL'},
	{name: '交通福利', value: 'CAR'},
	{name: '工会福利', value: 'COMMON'},
];
const categorylist_prod_op = [
	{name: '通用福利', value: 'DEFAULT'},
	{name: '餐饮福利', value: 'MEAL'},
	{name: '交通福利', value: 'CAR'},
	{name: '工会福利', value: 'COMMON'},
];
const categorylist_prod_llm = [
	{name: '便民生活', value: 'DEFAULT'},
	{name: '餐食外卖', value: 'MEAL'},
	{name: '交通出行', value: 'CAR'},
	{name: '通用津贴', value: 'COMMON'},
];

const categorylist_prod = project=='LLM'?categorylist_prod_llm:categorylist_prod_op;

const categorylist = appenv=='dev'?categorylist_dev:categorylist_prod;// dev|production

function getQueryString(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var reg_rewrite = new RegExp("(^|/)" + name + "/([^/]*)(/|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    var q = window.location.pathname.substr(1).match(reg_rewrite);
    if(r != null){
        return unescape(r[2]);
    }else if(q != null){
        return unescape(q[2]);
    }else{
        return null;
    }
}

function getRandomNumber(min,max)
{
    return Math.floor(Math.random()*(max-min+1)+min);
}


Date.prototype.Format = function(fmt) { // author: meizz
    var o = {
        "M+" : this.getMonth() + 1, // 月份
        "d+" : this.getDate(), // 日
        "h+" : this.getHours(), // 小时
        "m+" : this.getMinutes(), // 分
        "s+" : this.getSeconds(), // 秒
        "q+" : Math.floor((this.getMonth() + 3) / 3), // 季度
        "S" : this.getMilliseconds()
    // 毫秒
    };
    if (/(y+)/.test(fmt))
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for ( var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}

function parseDate(date,fmt){
    var dateStr = (date.Format(fmt)).toString();
    if(dateStr.indexOf("/")>0)
        return dateStr.replace(/\//g,"-");//"/"转换为"-"
    else
        return dateStr;
}


export default {
    baseURI, 
    baseImgURI,
	
	getQueryString,
	parseDate,
	getRandomNumber,
	
	categorylist: categorylist
}
</script>