<template>
	<div class="container-fluid relative animatedParent animateOnce my-3">
		<!-- row first -->
		<div class="row row-eq-height my-3 mt-4">
			<div class="col-md-6">
				<div class="row">
					<div class="col-md-6 col-sm-6">
						<div class="card no-b mb-3 bg-primary text-white">
							<div class="card-body">
								<div class="d-flex justify-content-between align-items-center">
									<div><i class="icon-restaurant_menu s-18"></i></div>
									<div><span class="text-success">
									{{ent_id==0?'备用金':'可用额度'}}
									</span></div>
								</div>
								<div class="text-center">
									<div class="s-48 my-3 font-weight-lighter">{{total_available.toFixed(2)}}</div>
									<!-- 企业代扣金额 -->
								</div>

							</div>
						</div>
					</div>
					<div class="col-md-6 col-sm-6">
						<!-- <div class="card no-b mb-3">
							<div class="card-body">
								<div class="d-flex justify-content-between align-items-center">
									<div><i class="icon-list s-18"></i></div>
									<div><span class="badge badge-pill badge-primary">今日实时</span></div>
								</div>
								<div class="text-center">
									<div class="s-48 my-3 font-weight-lighter">{{bill.count}}</div>
									订单数
								</div>

							</div>
						</div> -->
					</div>

				</div>


				<div class="row">
					<div class="col-md-6 col-sm-6">
						<div class="card no-b mb-3">
							<div class="card-body">
								<div class="d-flex justify-content-between align-items-center">
									<div><i class="icon-format_color_fill s-18"></i></div>
									<div><span class="badge badge-pill badge-primary">累计充值</span></div>
								</div>
								<div class="text-center">
									<div class="s-48 my-3 font-weight-lighter">{{total_pay.toFixed(2)}}</div>
									<!-- <template v-if="ent_id == 0 && dept_id == 0">P充A+历史上所有A充B</template>
									<template v-if="ent_id > 0 && dept_id == 0">P充A+历史上旗下A充B</template> -->
								</div>

							</div>
						</div>
					</div>
					<div class="col-md-6 col-sm-6">
						<div class="card no-b mb-3">
							<div class="card-body">
								<div class="d-flex justify-content-between align-items-center">
									<div><i class="icon-user-plus s-18"></i></div>
									<div><span class="text-primary">累计发放</span></div>
								</div>
								<div class="text-center">
									<div class="s-48 my-3 font-weight-lighter">{{total_used.toFixed(2)}}</div>
										<span class="s-12">
										 <template v-if="ent_id == 0 && dept_id == 0">所有企业发放给员工的额度总和</template>
										 <template v-if="ent_id > 0 && dept_id == 0">旗下企业发放给员工的额度总和</template>
									 </span>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- //firt left -->
			<!-- <div class="col-md-6" v-show="false">
				<div class="card no-b p-2">
					<div class="card-header no-b m-0  bg-white">
						<h5>本月趋势(发放与消费)</h5>
					</div>
					<div class="card-body p-3">
						<div class="height-300">
							<div id="main" style="width: 95%;height:300px;"></div>
						</div>
					</div>
				</div>
			</div> -->
		</div>
		<!-- //row first -->

		<!-- <div class="card-body" v-if="dept_id > 0">
			<div class="row my-3 no-gutters">
				<div class="col-md-3">
					<h1> 企业额度 </h1>
					<span class="s-16 font-weight-bold">总可用额度: {{total_quota.toFixed(2)}}元</span>
				</div>
				<div class="col-md-9">
					<div class="row">

						<div class="col-md-4" v-for="(item,index) in quota_list" v-bind:key="index">
							<div class="d-flex justify-content-between align-items-center">
								<div class="mb-3">
									<h6 class="text-primary">{{item.expense_name}}</h6>
									<h3>{{item.total_available.toFixed(2)}}元</h3>
								</div>
								<figure class="avatar-sm">
									<img :src="iconMap[item.expense_type]" alt="">
								</figure>
							</div>
							<div class="progress progress-xs mb-3">
								<div class="progress-bar bg-danger" role="progressbar" :style="{width: item.total_available/total_quota*100+'%'}" 
									aria-valuemin="0" aria-valuemax="100"></div>
							</div>
						</div>


					</div>
				</div>
			</div>
		</div> -->
		<!-- //card body , //row second -->
	</div>
</template>

<script>
	module.exports = {
		data: function() {
			return {
				title: '首页',
				hide: true,
				
				token: '',
				ent_id: '',
				dept_id: '',
				
				user_institution: {},
				bill: {},
				quota_list: [],
				total_quota: 0,
				// expenseTypeMap: {
				// 	'DEFAULT': '弹性福利',
				// 	'MEAL':'餐食外卖',
				// 	'CAR':'交通出行',
				// 	'COMMON': '工会福利',
				// },
				iconMap: {
					'MEAL': require("../assets/img/dummy/MEAL.png"),
					'DEFAULT': require("../assets/img/dummy/DEFAULT.png"),
					'CAR': require("../assets/img/dummy/CAR.png"),
					'COMMON': require("../assets/img/dummy/COMMON.png"),
				},
				
				////
				total_pay: 0,
				total_used: 0,
				total_available: 0,
			}
		},
		
		props: {
			// abc: { 
			// 	type: String,
			// 	default: ''
			// },
		},
		
		beforeCreate: function() {
			console.log('--- beforeCreate --- ')
		},
		
		created: function() {
			console.log('--- created --- ')
			let self = this;
		
			//1.检查登录
			let token = localStorage.getItem('TOKEN');
			if (!token) {
				return;
			}
			self.token = token;
		
			//2.取得店铺信息
			let lsLoginData = JSON.parse(localStorage.getItem('LOGINDATA'));
			self.ent_id = lsLoginData.ent_id;
			self.dept_id = lsLoginData.dept_id;
			
		},
		
		mounted: function() {
			console.log('--- mounted --- ')
			let self = this;
			
			//1.检查登录
			// let token = localStorage.getItem('TOKEN');
			// if (!self.token) {
			// 	console.log('--- mounted jump  --- ')
			// 	location.href = 'index.html';
			// 	return;
			// }
			
			// self.initData(() => {
			// 	self.initChart();
			// });
			
			self.init();
		},
		
		methods: {
			init() {
				let self = this;
					
				//
				this.axios.get(this.GLOBAL.baseURI + 'getQuotaSummaryV2', {
						params: {
							token: self.token,
							dept_id: self.dept_id,
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log("**** res->",data.data);
						if (data.code == 200) {
							
							// self.quota_list = [];
							// if(data.data.quota_list!=undefined){
							// 	self.quota_list = data.data.quota_list;
								
							// 	for(let i=0; i<self.quota_list.length; i++){
							// 		self.total_pay += self.quota_list[i].total_pay;
							// 		self.total_used += self.quota_list[i].total_used;
							// 		self.total_available += self.quota_list[i].total_available;
							// 	}
								
							self.total_pay = data.data.total_pay;
							self.total_used = data.data.total_used;
							self.total_available = data.data.total_available;
							
							// 		self.total_pay += self.quota_list[i].total_pay;
							// 		self.total_used += self.quota_list[i].total_used;
							
							// self.user_institution = data.data.user_institution;
							// self.bill = data.data.bill;
							
							// console.error('--- self.user_institution->',self.user_institution)
							// console.error('--- self.bill>',self.bill)
					
						} else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							self.$toasted.error( data.message , {
								position: 'top-center',
							}).goAway(2000)
							return;
						}
					});
				//END
					
			},
			
			initData(callback) {
				let self = this;
		
				//取得员工列表
				this.axios.get(this.GLOBAL.baseURI + 'getSaleSummaryOther', {
						params: {
							token: self.token,
							outlet_count: 1, //self.outletlist.length || 1,
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log("**** res->",data.data);
						if (data.code == 200) {
							
							if(data.data.today.amount!=undefined){
								self.sale_data = data.data.today;
							}
							
							if(data.data.can_recharge==1&& data.data.recharge_today.amount!=undefined){
								self.recharge_data = data.data.recharge_today;
							}
							
		
							let monthSaleData = data.data.month_saleorder_data;
							console.log('=== monthSaleData ->', monthSaleData)
							for (var i = 0; i < monthSaleData.length; i++) {
								self.chart_data.push({
									name: monthSaleData[i].key,
									value: [monthSaleData[i].key,monthSaleData[i].amount],
								})
							}
		
							console.info('=== chart_data ->', self.chart_data)
							
							// let cashierData = data.data.month_cashier_data;
							// for (var i = 0; i < cashierData.length; i++) {
							// 	self.cashier_data.push({
							// 		name: self.getEmployeeName(cashierData[i].key),
							// 		value: cashierData[i].price,
							// 	})
							// }
							self.cashier_data = data.data.month_cashier_data;
							console.log('--- cashier_data ->', self.cashier_data)
		
							if (typeof callback == 'function') {
								callback()
							}
						} else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							alert(data.message)
						}
					});
				//END
		
			},
			
			getEmployeeName(id){
				let self = this;
				let name =  '其它';
				console.log('--- id == 0 ->',id == 0);
				if(id == 0) return name;
				
				for(let emp of self.employeelist){
					console.log('--- emp id->',emp.id,id);
					if(emp.id == id){
						name =  emp.employee_name;
						break;
					}
				}
				
				return name;
			},
		
			onOutletChangeListener(e) {
				let self = this;
				console.log('--- outlet ID->', e)
				self.outlet_id = e;
		
				self.initData();
			},
		
			initChart() {
				console.log('--- init chart ---', this.$echarts)
				let self = this;
		
				var data = [{
						name: '2016/12/18 6:38:08',
						value: ['2016/12/18 6:38:08', 80]
					},
					{
						name: '2016/12/18 16:18:18',
						value: ['2016/12/18 16:18:18', 60]
					},
					{
						name: '2016/12/18 19:18:18',
						value: ['2016/12/18 19:18:18', 90]
					}
				];
		
		
				// console.log('--- data->',data)
		
				var myChart = this.$echarts.init(document.getElementById('main'));
		
				// 绘制图表
				myChart.setOption({
					// title: {
					// 	// text: '',
					// 	// subtext: '合计:' + self.sale_data.amount + '元', 
					// },
					// tooltip: {
					// 	trigger: 'axis',
					// 	formatter: function(params) {
					// 		params = params[0];
					// 		var date = new Date(params.name);
					// 		return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date
					// 			.getFullYear() + ' : ' + params.value[1];
					// 	},
					// 	axisPointer: {
					// 		animation: false
					// 	}
					// },
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross',
							label: {
								backgroundColor: '#6a7985'
							}
						}
					},
					// tooltip: {
					// 	// trigger: 'axis',
					// 	formatter: function (param) {
					// 		return  param.value[0] + "<br>" + param.value[1] + "元";
					// 	}
					// },
					
					xAxis: {
						type: 'time',
						splitNumber: 5,
						splitLine: {
							show: false
						},
						axisLabel: {
							// formatter: '{MM}-{dd}'
							formatter: (function(value){
								 // console.log('--- value->',value)
								let xDate = new Date(value);
								return xDate.getMonth()+1+"月"+xDate.getDate()+"日"
							})
						}
		
					},
					// xAxis: {
					// 	type: 'category',
					// 	boundaryGap: false,
					// 	data: ['00:00', '01:15', '02:30', '03:45', '05:00', '06:15', '07:30', '08:45', '10:00', '11:15', '12:30', '13:45', '15:00', '16:15', '17:30', '18:45', '20:00', '21:15', '22:30', '23:45']
					// },
					yAxis: {
						type: 'value',
						// boundaryGap: [0, '100%'],
						splitLine: {
							show: false
						},
						axisLabel: {
							formatter: '{value} 元'
						}
					},
					series: [{
						name: '营业额',
						type: 'line',
						smooth: 0.6,
						// symbol: 'circle',
						lineStyle: {
							color: '#003399',
							width: 4
						},
						label: {
							show: false,
							position: 'top',
							formatter: '{@[1]} 元',
							color: '#003399',
						},
						
						// sampling: 'lttb',
						// itemStyle: {
						// 	color: 'rgb(255, 70, 131)'
						// },
						// areaStyle: {
						// 	color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
						// 		offset: 0,
						// 		color: 'rgb(255, 158, 68)'
						// 	}, {
						// 		offset: 1,
						// 		color: 'rgb(255, 70, 131)'
						// 	}])
						// },
						data: self.chart_data //data
								
					}]
				});
				//chart end
			},
		},
	}
</script>

<style>
</style>
