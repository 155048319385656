<template>
    <div>
        <aside class="main-sidebar fixed offcanvas b-r sidebar-tabs" data-toggle='offcanvas'>
            <div class="sidebar">
                <div class="d-flex hv-100 align-items-stretch">
                    <!-- 一级导航 amber darken-3  -->
                    <div class="amber lighten-1">
                        <div class="nav mt-5 pt-5 flex-column nav-pills" id="v-pills-tab" role="tablist"
                            aria-orientation="vertical">
                            <!-- @click="goHome" -->
                            <a @click="goHome" class="nav-link"><i class="icon-home"></i><span
                                    class="nav-subtitle">首页</span></a>
									
                            <a  v-if="user_role=='ADMIN' || user_role=='AGENT'" @click="expandmenu('product')" class="nav-link" id="v-pills-product-tab"
                                data-toggle="pill" href="#v-pills-product" role="tab" aria-controls="v-pills-product"
                                aria-selected="false"><i class="icon-restaurant"></i><span
                                    class="nav-subtitle">企业</span></a>
									
                            <a  v-if="user_role=='USER'" @click="expandmenu('customer')" class="nav-link" id="v-pills-customer-tab"
                                data-toggle="pill" href="#v-pills-customer" role="tab" aria-controls="v-pills-customer"
                                aria-selected="false"><i class="icon-user"></i><span
                                    class="nav-subtitle">员工</span></a>

                           <a v-if="user_role=='ADMIN' || user_role=='USER'" @click="expandmenu('ins')" class="nav-link" id="v-pills-ins-tab" data-toggle="pill"
                                href="#v-pills-ins" role="tab" aria-controls="v-pills-ins"
                                aria-selected="false"><i class="icon-gift"></i>
								<span class="nav-subtitle">制度</span></a>
								
							<a v-if="user_role=='ADMIN' || user_role=='USER'" @click="expandmenu('coupon')" class="nav-link" id="v-pills-coupon-tab" data-toggle="pill"
							     href="#v-pills-coupon" role="tab" aria-controls="v-pills-coupon"
							     aria-selected="false"><i class="icon-credit-card-alt"></i>
								<span class="nav-subtitle">福利卡</span></a>	

                            <a v-if="user_role=='USER'" @click="expandmenu('orders')" class="nav-link" id="v-pills-orders-tab" data-toggle="pill"
                                href="#v-pills-orders" role="tab" aria-controls="v-pills-orders"
                                aria-selected="false"><i class="icon-table"></i><span class="nav-subtitle">账单</span></a>

                            <a v-if="user_role=='ADMIN' || user_role=='AGENT'" @click="expandmenu('report')" class="nav-link" id="v-pills-report-tab" data-toggle="pill"
                                href="#v-pills-report" role="tab" aria-controls="v-pills-report"
                                aria-selected="false"><i class="icon-area-chart"></i><span
                                    class="nav-subtitle">报表</span></a>

                            <a @click="expandmenu('setting')" class="nav-link" id="v-pills-setting-tab"
                                data-toggle="pill" href="#v-pills-setting" role="tab" aria-controls="v-pills-setting"
                                aria-selected="false"><i class="icon-gear"></i><span class="nav-subtitle">设置</span></a>


                            <a @click="expandmenu('user')" class="nav-link" id="v-pills-user-tab" data-toggle="pill"
                                href="#v-pills-user" role="tab" aria-controls="v-pills-user" aria-selected="false">
                                <figure class="avatar">
                                    <img src="../assets/img/dummy/u1.png" alt="">
                                    <span class="avatar-badge online"></span>
                                </figure>
                            </a>
                        </div>
                    </div>
                    <!-- //一级导航 -->

                    <!-- 二级导航 -->
                    <div class="tab-content flex-grow-1" id="v-pills-tabContent">
                        <!-- 菜品 -->
                        <div  v-if="user_role=='ADMIN' || user_role=='AGENT'" class="tab-pane fade" id="v-pills-product" role="tabpanel"
                            aria-labelledby="v-pills-product-tab">
                            <div class="relative brand-wrapper sticky b-b">
                                <div class="d-flex justify-content-between align-items-center p-3">
                                    <div class="text-xs-center">
                                        <span class="font-weight-lighter s-18">企业</span>
                                    </div>
                                    <div data-toggle="push-menu" class="icon icon-angle-double-left r-0"
                                        style="font-size:24px;"></div>
                                </div>
                            </div>
                            <ul class="sidebar-menu">
								<li class="treeview"  v-if="user_role=='AGENT'">
								    <a>
								        <i class="icon icon-flag"></i> <span class="s-16">
								            <router-link :to="{name:'enterprises'}">企业码列表</router-link>
								        </span>
								    </a>
								</li>
								
								<li class="treeview"  v-if="user_role=='AGENT'">
								    <a>
								        <i class="icon icon-storage"></i> <span class="s-16">
								            <router-link :to="{name:'entquotas'}">服务商额度管理</router-link>
								        </span>
								    </a>
								</li>
								
                                <li class="treeview"  v-if="user_role=='ADMIN'">
                                    <a>
                                        <i class="icon icon-class"></i> <span class="s-16">
                                            <router-link :to="{name:'departments'}">企业列表</router-link>
                                        </span>
                                    </a>
                                </li>
								<li class="treeview"  v-if="user_role=='ADMIN'">
								    <a>
								        <i class="icon icon-storage"></i> <span class="s-16">
								            <router-link :to="{name:'quotas'}">企业额度管理</router-link>
								        </span>
								    </a>
								</li>
                               <li class="treeview"  v-if="user_role=='ADMIN'">
                                    <a>
                                        <i class="icon icon-restaurant"></i> <span class="s-16">
                                            <router-link to="/admin/merchants">商户白名单</router-link>
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <!-- //菜品 -->
						
                        <!-- 会员 -->
                        <div  v-if="user_role=='USER'" class="tab-pane fade " id="v-pills-customer" role="tabpanel"
                            aria-labelledby="v-pills-customer-tab">
                            <div class="relative brand-wrapper sticky b-b">
                                <div class="d-flex justify-content-between align-items-center p-3">
                                    <div class="text-xs-center">
                                        <span class="font-weight-lighter s-18"> 员工</span>
                                    </div>
                                    <div data-toggle="push-menu" class="icon icon-angle-double-left r-0"
                                        style="font-size:24px;"></div>
                                </div>
                            </div>
                            <ul class="sidebar-menu">
                                <li class="treeview" v-if="user_role=='USER'">
                                    <a>
                                        <i class="icon icon-class"></i> <span class="s-16">
                                            <router-link :to="{name: 'subdepartments'}">部门列表</router-link>
                                        </span>
                                    </a>
                                </li>
                               <li class="treeview" v-if="user_role=='USER'">
                                    <a>
                                        <i class="icon icon-person_pin"></i> <span class="s-16">
                                            <router-link :to="{name: 'staffs'}">员工列表</router-link>
                                        </span>
                                    </a>
                                </li>
                                <!-- <li class="treeview" v-if="outlet_level==1">
                                    <a>
                                        <i class="icon icon-plus"></i> <span class="s-16">
                                            <router-link :to="{name: 'editcustomer'}">创建员工</router-link>
                                        </span>
                                    </a>
                                </li> -->
                            </ul>
                        </div>
                        <!-- //会员 -->
						
                        <!-- 制度 -->
                        <div class="tab-pane fade " id="v-pills-ins" role="tabpanel"
                            aria-labelledby="v-pills-ins-tab">
                            <div class="relative brand-wrapper sticky b-b">
                                <div class="d-flex justify-content-between align-items-center p-3">
                                    <div class="text-xs-center">
                                        <span class="font-weight-lighter s-18"> 制度</span>
                                    </div>
                                    <div data-toggle="push-menu" class="icon icon-angle-double-left r-0"
                                        style="font-size:24px;"></div>
                                </div>
                            </div>
                            <ul class="sidebar-menu">
                                <li class="treeview">
                                    <a>
                                        <i class="icon icon-class"></i> <span class="s-16">
                                            <router-link :to="{name: 'institutions'}">制度列表</router-link>
                                        </span>
                                    </a>
                                </li>
								<li class="treeview" v-if="user_role=='ADMIN'">
								    <a>
								        <i class="icon icon-plus"></i> <span class="s-16">
								            <router-link :to="{name: 'addinstitution'}">创建制度</router-link>
								        </span>
								    </a>
								</li>
								
								<li class="treeview"  v-if="user_role=='USER'">
								    <a>
								        <i class="icon icon-class"></i> <span class="s-16">
								            <router-link :to="{name: 'userinstitutions'}">发放记录</router-link>
								        </span>
								    </a>
								</li>

                            </ul>
                        </div>
                        <!-- //制度 -->
						
						<!-- 营销 -->
						<div class="tab-pane fade " id="v-pills-coupon" role="tabpanel"
						    aria-labelledby="v-pills-coupon-tab">
						    <div class="relative brand-wrapper sticky b-b">
						        <div class="d-flex justify-content-between align-items-center p-3">
						            <div class="text-xs-center">
						                <span class="font-weight-lighter s-18"> 制度</span>
						            </div>
						            <div data-toggle="push-menu" class="icon icon-angle-double-left r-0"
						                style="font-size:24px;"></div>
						        </div>
						    </div>
						    <ul class="sidebar-menu">
						       <li class="treeview" v-if="user_role=='ADMIN'">
						           <a>
						               <i class="icon icon-class"></i> <span class="s-16">
						                   <router-link :to="{name: 'giftcardstocks'}">福利卡批次</router-link>
						               </span>
						           </a>
						       </li>
							   
								<!-- <li class="treeview" v-if="user_role=='ADMIN'">
								    <a>
								        <i class="icon icon-plus"></i> <span class="s-16">
								            <router-link :to="{name: 'addgiftcardstock'}">创建福利卡批次</router-link>
								        </span>
								    </a>
								</li> -->
								
								<li class="treeview">
								    <a>
								        <i class="icon icon-class"></i> <span class="s-16">
								            <router-link :to="{name: 'giftcards'}">福利卡列表</router-link>
								        </span>
								    </a>
								</li>
								
								<li class="treeview" v-if="user_role=='USER'">
								    <a>
								        <i class="icon icon-class"></i> <span class="s-16">
								            <router-link :to="{name: 'giftcardlogs'}">发放记录</router-link>
								        </span>
								    </a>
								</li>
						
						    </ul>
						</div>
						<!-- //营销 -->
						
                        <!-- 订单 -->
                        <div v-if="user_role=='USER'" class="tab-pane fade " id="v-pills-orders" role="tabpanel"
                            aria-labelledby="v-pills-orders-tab">
                            <div class="relative brand-wrapper sticky b-b">
                                <div class="d-flex justify-content-between align-items-center p-3">
                                    <div class="text-xs-center">
                                        <span class="font-weight-lighter s-18"> 账单</span>
                                    </div>
                                    <div data-toggle="push-menu" class="icon icon-angle-double-left r-0"
                                        style="font-size:24px;"></div>
                                </div>
                            </div>
                            <ul class="sidebar-menu">
                                <li class="treeview" v-if="user_role=='USER'">
                                    <a>
                                        <i class="icon icon-table"></i> <span class="s-16">
                                            <router-link :to="{name: 'bills'}">账单明细</router-link>
                                        </span>
                                    </a>
                                </li>
                                <!-- <li class="treeview">
                                    <a>
                                        <i class="icon icon-money-1"></i> <span class="s-16">
                                            <router-link :to="{name: 'rechargeorders'}">发票管理</router-link>
                                        </span>
                                    </a>
                                </li> -->

                            </ul>
                        </div>
                        <!-- //订单 -->
						
                        <!-- 报表 -->
                        <div class="tab-pane fade " id="v-pills-report" role="tabpanel"
                            aria-labelledby="v-pills-report-tab">
                            <div class="relative brand-wrapper sticky b-b">
                                <div class="d-flex justify-content-between align-items-center p-3">
                                    <div class="text-xs-center">
                                        <span class="font-weight-lighter s-18"> 报表</span>
                                    </div>
                                    <div data-toggle="push-menu" class="icon icon-angle-double-left r-0"
                                        style="font-size:24px;"></div>
                                </div>
                            </div>
                            <ul class="sidebar-menu">
								<li class="treeview" v-if="user_role=='AGENT'">
								    <a>
								        <i class="icon icon-bar-chart"></i> <span class="s-16">
								            <router-link :to="{name: 'entbalancereport'}">服务商余额报表</router-link>
								        </span>
								    </a>
								</li>
								
								<li class="treeview" v-if="user_role=='AGENT'">
								    <a>
								        <i class="icon icon-table"></i> <span class="s-16">
								            <router-link :to="{name: 'entweekbillreport'}">周消费报表</router-link>
								        </span>
								    </a>
								</li>
								
								<li class="treeview" v-if="user_role=='ADMIN'">
								    <a>
								        <i class="icon icon-pie-chart"></i> <span class="s-16">
								            <router-link :to="{name: 'quotareport'}">额度报表</router-link>
								        </span>
								    </a>
								</li>
								<li class="treeview" v-if="user_role=='ADMIN'">
								    <a>
								        <i class="icon icon-table"></i> <span class="s-16">
								            <router-link :to="{name: 'giftcardreport'}">福利卡报表</router-link>
								        </span>
								    </a>
								</li>
								<li class="treeview" v-if="user_role=='ADMIN'">
								    <a>
								        <i class="icon icon-bar-chart"></i> <span class="s-16">
								            <router-link :to="{name: 'balancereport'}">企业余额报表</router-link>
								        </span>
								    </a>
								</li>
								<li class="treeview" v-if="user_role=='ADMIN'">
								    <a>
								        <i class="icon icon-table"></i> <span class="s-16">
								            <router-link :to="{name: 'weekbillreport'}">周消费报表</router-link>
								        </span>
								    </a>
								</li>
                                <li class="treeview" v-if="user_role=='USER'">
                                    <a>
                                        <i class="icon icon-line-chart"></i> <span class="s-16">
                                            <router-link :to="{name: 'userinstitutionreport'}">发放报表</router-link>
                                        </span>
                                    </a>
                                </li>
                                <li class="treeview" v-if="user_role=='USER'">
                                    <a>
                                        <i class="icon icon-bar-chart"></i> <span class="s-16">
                                            <router-link :to="{name: 'billreport'}">账单报表</router-link>
                                        </span>
                                    </a>
                                </li>
                                <!-- <li class="treeview">
                            <a href="paymentreport.html">
                                <i class="icon icon-pie-chart"></i> <span class="s-16">支付报表</span>
                            </a>
                        </li> -->
								
								
                            </ul>
                        </div>
                        <!-- //报表 -->
						
                        <!-- 设置 -->
                        <div class="tab-pane fade " id="v-pills-setting" role="tabpanel"
                            aria-labelledby="v-pills-setting-tab">
                            <div class="relative brand-wrapper sticky b-b">
                                <div class="d-flex justify-content-between align-items-center p-3">
                                    <div class="text-xs-center">
                                        <span class="font-weight-lighter s-18">设置</span>
                                    </div>
                                    <div data-toggle="push-menu" class="icon icon-angle-double-left r-0"
                                        style="font-size:24px;"></div>
                                </div>
                            </div>

                            <ul class="sidebar-menu">
                                <!-- <li class="treeview">
                            <a href="setting.html">
                                <i class="icon icon-user-circle"></i> <span class="s-16">帐号</span>
                            </a>
                        </li> -->
                                <li class="treeview" v-if="user_role=='AGENT'">
                                    <a>
                                        <i class="icon icon-user-o"></i> <span class="s-16">
                                            <router-link :to="{name: 'ausers'}">A端用户管理</router-link>
                                        </span>
                                    </a>
                                </li>
								<li class="treeview" v-if="user_role=='ADMIN'">
								    <a>
								        <i class="icon icon-user-o"></i> <span class="s-16">
								            <router-link :to="{name: 'users'}">企业用户管理</router-link>
								        </span>
								    </a>
								</li>
                                <li class="treeview" v-if="user_role=='USER'">
                                    <a>
                                        <i class="icon icon-gear"></i> <span class="s-16">
                                            <router-link :to="{name: 'setting'}">系统设置</router-link>
                                        </span>
                                    </a>
                                </li>
								<li class="treeview"  v-if="user_role=='ADMIN' || user_role=='USER'">
								    <a>
								        <i class="icon icon-message"></i> <span class="s-16">
								            <router-link :to="{name: 'messages'}">消息管理</router-link>
								        </span>
								    </a>
								</li>
								<li class="treeview" v-if="user_role=='ADMIN'">
								    <a>
								        <i class="icon icon-note-list"></i> <span class="s-16">
								            <router-link :to="{name: 'userlogs'}">企业登录日志</router-link>
								        </span>
								    </a>
								</li>
								<li class="treeview" v-if="user_role=='AGENT'">
								    <a>
								        <i class="icon icon-note-list"></i> <span class="s-16">
								            <router-link :to="{name: 'entuserlogs'}">服务商登录日志</router-link>
								        </span>
								    </a>
								</li>
                            </ul>
                        </div>
                        <!-- //设置 -->
                        <!-- 登录用户 -->
                        <div class="tab-pane fade " id="v-pills-user" role="tabpanel"
                            aria-labelledby="v-pills-user-tab">
                            <div class="relative brand-wrapper sticky b-b">
                                <div class="d-flex justify-content-between align-items-center p-3">
                                    <div class="text-xs-center">
                                        <span class="font-weight-lighter s-16">帐号{{user_role}}</span>
                                    </div>
                                    <div data-toggle="push-menu" class="icon icon-angle-double-left r-0"
                                        style="font-size:24px;"></div>
                                </div>
                            </div>
                            <ul class="sidebar-menu">

                                <li class="treeview">
                                    <a>
                                        <i class="icon icon-user"></i> <span class="s-16">
                                            <router-link :to="{name: 'account'}">帐号与安全</router-link>
                                        </span>
                                    </a>
                                </li>
                                <li class="treeview" @click.stop="logout">
                                    <a>
                                        <i class="icon icon-power-off"></i> <span class="s-16"> 退出登录 </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <!-- //登录用户 -->
                    </div>
                    <!-- //二级导航 -->
                </div>
            </div>
        </aside>
        <div class="has-sidebar-left">
            <div class="pos-f-t">
                <div class="collapse" id="navbarToggleExternalContent">
                    <div class="bg-dark pt-2 pb-2 pl-4 pr-2">
                        <div class="search-bar">
                            <input class="transparent s-24 text-white b-0 font-weight-lighter w-128 height-50"
                                type="text" placeholder="搜索...">
                        </div>
                        <a href="#" data-toggle="collapse" data-target="#navbarToggleExternalContent"
                            aria-expanded="false" aria-label="Toggle navigation"
                            class="paper-nav-toggle paper-nav-white active "><i></i></a>
                    </div>
                </div>
            </div>
        </div>

        <a class="paper-nav-toggle left ml-2 fixed">
            <img src="../assets/img/logo-255x255.png" class="avatar" alt="两粒米福利云 | 米粒付">
            <span class="badge badge-dark r-20 p-1 mt-2" style="font-size: 9px;">米粒付</span>
        </a>
    </div>
</template>

<script>
    module.exports = {
        data: function() {
            return {
				menu: '',
			}
        },
        props: {
            // outlet_name: { // 
            //     type: String,
            //     default: ''
            // },
            // user_name: { //  
            //     type: String,
            //     default: ''
            // },
            // outlet_level: { //
            //     type: Number,
            //     default: 1
            // },
			user_role: { //
			    type: String,
			    default: ''
			},
        },
        methods: {
            goHome() {
                this.$router.push({
                    path: '/admin/dashboard'
                }).catch(err => {err});
            },

            expandmenu(menu) {
                console.log('--- 菜单:' + menu, $('body').attr('class'), $('body').hasClass('sidebar-collapse'))

                //collapse折叠失效补偿:当 body不存在sidebar-collapse类时，手动添加，使其展开
                if ($('body').hasClass('sidebar-collapse')) {
                    $('body').removeClass('sidebar-collapse');
                }
            },

            logout() {
                //TODO:调用 logout 接口，清除服务器端登录状态
            	let self = this;
				
				let token = localStorage.getItem('TOKEN');
				if (!token) {
				    return;
				}
            	
            	this.axios.get(this.GLOBAL.baseURI + 'logoff', {
            	    params: {token: token}
            	})
            	.then(function(response) {
            	    console.log(response);
            	    return response.data;
            	})
            	.catch(function(error) {
            	    console.log(error);
            	})
            	.then(function(data) {
            	    // always executed
            	    console.log(data.data);
            	    
            	    
            	    if (data.code == 200) { 
            			self.$toasted.success( '退出登录', {
            				position: 'top-center',
            				duration: '2000',
            			})
            	        
            			setTimeout(()=>{
            				localStorage.clear();
            				self.$router.push({name:'login'})
            			},1000)
            	       
            	    }
            	    else{
            	        // alert(data.message)
            			self.$toasted.error( data.message, {
            				position: 'top-center',
            				duration: '2000',
            			})
            	    }
            	});
            	//END
            },

        }
    }
</script>

<style>
    .nav-subtitle {
        display: block;
        font-size: 9px;
    }

    .nav-pills .nav-link.active,
    .nav-pills .show>.nav-link {
        color: #fff;
        background-color: #444
    }

    .router-link-exact-active,
    .router-link-active {
        color: #86939e;
        font-size: 16px;
    }

    .router-link-exact-active:hover,
    .router-link-active:hover {
        color: #86939e;
    }

    /* 首页 */
    /* .nav-subtitle{
        display: block !important;
        font-size: 9px !important;
        padding: 0 !important;
    } */
</style>
